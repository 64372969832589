<template>
    <div>
        <v-toolbar
                dense
                color="primary darken-2"
                dark
                extended
                extension-height="210"
                flat
        >
            <v-toolbar-title class="mx-auto" slot="extension">
                <span v-if="$store.state.isAuthenticated">Welcome <b>{{$store.state.user_info["user"]["username"]}} 😃</b>, to PettyCash: Fundraising</span>
                <span v-else >Welcome 😃 to <b>PettyCash: Fundraising</b>,</span>
                <h2 class="saving_heading">We bring life into your dream projects.</h2>
                <v-layout row >
                    <v-flex wrap xs2 sm2 md2 lg2 >
                        <v-btn outline  fab color="white" class="outine-2">
                            <i class="fas fa-coins fa-3x"></i>
                        </v-btn>
                    </v-flex>
                    <v-flex wrap xs2 sm2 md2 lg2 >
                        <v-btn flat  fab color="white">
                            <i class="fas fa-plus fa-2x"></i>
                        </v-btn>
                    </v-flex>
                    <v-flex wrap xs2 sm2 md2 lg2 >
                       <v-btn outline  fab color="white" class="outine-2">
                            <i class="fas fa-hand-holding-usd fa-2x"></i>
                        </v-btn>
                    </v-flex>
                    
                    <v-flex wrap xs1 sm1 md2 lg2 >
                        <v-btn flat  fab color="white">
                            <i class="fas fa-arrow-right-long fa-2x"></i>
                        </v-btn>
                    </v-flex>
                    
                    <v-flex wrap xs2 sm3 md3 lg3 >
                        <v-img
                            :src="require('@/assets/funding.png')"
                            aspect-ratio="2"  
                            contain
                            height="90"
                            width="150"
                        ></v-img>
                    </v-flex>
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>

        <v-container class="mt-5">
            <v-layout row>
                <v-flex xs12 md10 offset-md1 lg10 offset-lg1>
                    <v-card flat class="card--flex-toolbar" color="transparent">
                        <v-container fluid grid-list-lg>
                            <v-layout row wrap>
                                <v-flex xs12 class="mt-0 pt-0 mb-2">
                                    <h3 class="white--text">Explore Petty Ca$h Fundraising</h3>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex xs12 sm6 md6 lg3 v-for="item in links" :key="item.id">
                                    <v-hover>
                                        <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 4}`" style="border-radius: 5px; font-size: small" :to="item.link">
                                            <v-layout row >
                                                <v-flex xs8>
                                                    <v-btn fab :color="item.color" class="mt-2 ml-3 mb-0 mr-2 white--text">
                                                        <i :class="item.icon"></i>
                                                    </v-btn>
                                                </v-flex>
                                                <v-flex xs4 v-if="!(item.title == 'Legal Bindings')" class="text-xs-right">
                                                    <v-menu bottom right >
                                                        <v-btn slot="activator" icon flat fab color="mt-2 ml-3 mb-0" v-on:click.prevent="activateMenu(item.title)">
                                                            <i class="fa-solid fa-ellipsis-vertical fa-2x"></i>
                                                        </v-btn>
                                                        <v-list class="grey lighten-4">
                                                        <v-list-tile v-for="menuLink in menuItems" :key="menuLink.title" :to="menuLink.link">
                                                            <v-list-tile-title>{{ menuLink.title }}</v-list-tile-title>
                                                        </v-list-tile>
                                                        </v-list>
                                                    </v-menu>
                                                </v-flex>
                                            </v-layout>
                                            <v-card-title primary-title class="pb-0 mt-0 pt-1" style="color: #424242">
                                                <div>
                                                    <h5 class="subheading pl-0 pb-0 mb-0"><b>{{item.title}}</b></h5>
                                                    <div class="caption mt-0 mb-0 pb-2">{{item.description}}</div>
                                                </div>
                                            </v-card-title>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                menuItems: [],
                links: [
                    {
                        id: '1',
                        title: 'Projects',
                        description: 'View and learn of our current projects which you can invest into and buy shares or donate.',
                        icon: 'fa-solid fa-project-diagram fa-2x',
                        color: 'orange',
                        link: '/funding/projects/types/active',
                        add_link: false
                    },
                    {
                        id: '3',
                        title: 'Events',
                        description: 'View, create and invite others to raise funds for one-time events',
                        icon: 'fa-solid fa-calendar-alt fa-2x',
                        color: 'blue',
                        link: '/funding/events/types/active',
                        add_link: false
                    },
                    {
                        id: '4',
                        title: 'Contact Us',
                        description: 'Authenticate and manage users from a variety of providers without server-side-code',
                        icon: 'fa-solid fa-address-card fa-2x',
                        color: 'green',
                        link: '/funding/contact_us/all',
                        add_link: false
                    },
                    {
                        id: '5',
                        title: 'Legal Bindings',
                        description: 'Authenticate and manage users from a variety of providers without server-side-code',
                        icon: 'fa-solid fa-file-contract fa-2x',
                        color: 'purple',
                        link: '/funding/legal_binding/all',
                        add_link: false
                    }
                ]
            }
        },
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | Fundraising"
        },

        methods:{
            activateMenu(title){
                if(title == "Projects"){
                    this.menuItems = [
                        {
                            "title": "Active",
                            "link": "/funding/projects/types/active",
                        },
                        {
                            "title": "Terminated",
                            "link": "/funding/projects/types/terminated",
                        },
                        {
                            "title": "Most Bought",
                            "link": "/funding/projects/types/my_projects",
                        },
                    ]
                }
                else if(title == "Events"){
                    this.menuItems = [
                        {
                            "title": "Active",
                            "link": "/funding/events/types/active",
                        },
                        {
                            "title": "Terminated",
                            "link": "/funding/events/types/terminated",
                        },
                        {
                            "title": "My Events",
                            "link": "/funding/events/types/my_events",
                        },
                    ]
                }
                else if(title == "Contact Us"){
                    this.menuItems = [
                        {
                            "title": "All Chats",
                            "link": "/funding/contact_us/all",
                        },
                        {
                            "title": "Create New Chat",
                            "link": "/funding/contact_us/new_ticket",
                        },
                    ]
                }
            },
        },
    }
</script>

<style scoped>
    .outine-2 {
        border: 2px solid white;
    }
    @media (max-width: 499px) {
        .saving_heading{
            font-size: 12px
        }
    }

    .card--flex-toolbar {
        margin-top: -124px;
    }

    .learn-more-btn {
        text-transform: initial;
        text-decoration: underline;
    }
</style>
